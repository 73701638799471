import { Pages, trackLandingPageGoNext, trackPageSource } from "../../mixpanel";
import { StyledH3, StyledHeading, StyledLandingButton, StyledLandingCardContainer, StyledLandingPageInnerContainer, StyledLandingPageWrapper } from "./styles";
import { setGenre, setGoalType } from "../../store/growthSimulator/growthSimulator.actions";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { EGoalType } from "../../store/growthSimulator/growthSimulator.state";
import { ReactComponent as ScrollDownIcon } from "../../assets/scroll-down.svg";
import SecondLanding from "./SecondLanding";
import ThirdLanding from "./ThirdLanding";
import board from '../../assets/board.png';
import { device } from "../../helpers/device";
import girl from '../../assets/girl.png';
import logo from '../../assets/landing_logo.png';
import styled from "styled-components";
import { useDispatch } from "react-redux";

type LandingContent = {
  h1Text: string,
  h2Text: string,
  buttonText: string,
  genre?: string
};

enum Genre {
  Gaming = 'gaming',
  SaaS = 'saas'
};

const scenarios: Record<string, LandingContent> = {
  'b18aaf4b': {
    h1Text: 'Calculate Customer Lifetime Value (CLV) in 30 seconds!',
    h2Text: 'Don\'t spend hours on Excel trying to figure out how to calculate your CLV. Let Simpool do the work for you.',
    buttonText: 'Calculate my CLV for Free!',
    genre: Genre.Gaming
  },
  'f1779a1c': {
    h1Text: 'Run your own CLV calculation examples in 30 seconds!',
    h2Text: 'You don\'t need to deal with complicated CLV formulas. Simpool\'s powerful CLV calculator will do the work for you',
    buttonText: 'Start for free',
    genre: Genre.Gaming
  },
  '9300e9bf' : {
    h1Text: 'Get your customer LTV in 30 seconds with Simpool\'s CLV calculator',
    h2Text: 'Don\'t spend hours on Excel trying to figure out how to calculate your CLV. Let Simpool do the work for you.',
    buttonText: 'Calculate my CLV for Free!',
    genre: Genre.Gaming
  },
  '8a1ee7d2' : {
    h1Text: 'Calculate customer LTV in 30 seconds without any formula',
    h2Text: 'Don\'t spend hours on Excel trying to figure out how to calculate your CLV. Let Simpool do the work for you.',
    buttonText: 'Calculate my CLV for Free!',
    genre: Genre.Gaming
  },
  '3511eec9' : {
    h1Text: 'Calculate SaaS customer Lifetime value in 30 seconds ',
    h2Text: 'Don\'t spend hours on Excel trying to figure out how to calculate your LTV. Let Simpool do the work for you.',
    buttonText: 'Calculate my LTV for Free!',
    genre: Genre.SaaS
  },
  '0fd83b8b' : {
    h1Text: 'Calculate LTV in 30 seconds',
    h2Text: 'Don\'t spend hours on Excel trying to figure out how to calculate your LTV. Let Simpool do the work for you.',
    buttonText: 'Calculate my LTV for Free!',
    genre: Genre.SaaS
  },
  'default': {
    h1Text: 'Get predictive LTV in 30 seconds!',
    h2Text: 'Explore an infinite number of scenarios!',
    buttonText: 'Let\'s go for Free',
    genre: Genre.Gaming

  }
};

//INFO(PPavlov): IPhone 12
const mobileMatchMedia = window.matchMedia("(max-width: 390px)");

const Landing = () => {

  const [isMobileMode, setIsMobileMode] = useState(mobileMatchMedia.matches);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleGoToTheNexStep = () => {
    // scenarios[hash!]?.genre && scenarios[hash!]?.genre === Genre.SaaS && dispatch(setGoalType(EGoalType.SassGoal))
    history.push(`/growth-simulator-v2/details`);
    trackLandingPageGoNext();
  };

  const onResolutionChange = () => {
    console.log(mobileMatchMedia.matches);
    setIsMobileMode(mobileMatchMedia.matches);
  };

  useEffect(() => {
    mobileMatchMedia.addListener(onResolutionChange);

    return () => {
      mobileMatchMedia.removeListener(onResolutionChange);
    };
  }, []);
  
  const { search } = useLocation();
  const source = new URLSearchParams(search).get('src') || undefined;
  const hash = new URLSearchParams(search).get('hash') || undefined;

  useEffect(() => {
    trackPageSource(Pages.Landing, source)
  }, []);
  
  useEffect(() => {
    hash && dispatch(setGenre(scenarios[hash].genre as Genre));
  }, [hash])

  return (
    <div>
      <StyledLandingPageWrapper>
      {!isMobileMode && <img className="avatar" src={girl} alt="" />}
        <StyledLandingPageInnerContainer>
          <StyledHeaderContainer>
            <img className="logotype" src={logo} alt="" />
          </StyledHeaderContainer>
          <StyledTextSectionContainer>
            {!isMobileMode && <img className="board" src={board} alt="" />}
            <StyledTextSection>
              <StyledHeading onClick={handleGoToTheNexStep}>
                { scenarios[hash!]?.h1Text || scenarios['default'].h1Text}
              </StyledHeading>
              <StyledH3>
                { scenarios[hash!]?.h2Text || scenarios['default'].h2Text}
              </StyledH3>
              <StyledLandingCardContainer>
                <div className="pre-text">Already used for</div>
                <div className="big-text">100,000+</div>
                <div className="small-text">Scenarios</div>
              </StyledLandingCardContainer>
              <StyledLandingCardContainer>
                <div className="pre-text">By</div>
                <div className="big-text">2,000+</div>
                <div className="small-text">Customers</div>
              </StyledLandingCardContainer>
              <StyledLandingCardContainer>
                <div className="big-text">$1B</div>
                <div className="small-text">In Marketing Budget</div>
              </StyledLandingCardContainer>
              <StyledLandingButton
                variant="contained"
                size="large"
                onClick={handleGoToTheNexStep}
                style={{ marginTop: '30px'}}
              >{ scenarios[hash!]?.buttonText || scenarios['default'].buttonText}</StyledLandingButton>
            </StyledTextSection>
          </StyledTextSectionContainer>
        </StyledLandingPageInnerContainer>
        <ScrollDownIcon
            style={{ fill: "white", height: "35px", width: "35px" }}
            className="scroll-icon"
          />
      </StyledLandingPageWrapper>
      <SecondLanding buttonOnClick={handleGoToTheNexStep} buttonText={ scenarios[hash!]?.buttonText || scenarios['default'].buttonText }/>
      <ThirdLanding buttonOnClick={handleGoToTheNexStep} buttonText={ scenarios[hash!]?.buttonText || scenarios['default'].buttonText }/>
    </div>
  );
};


export default Landing;


const StyledHeaderContainer = styled.header`
  height: 70px;
  width: 100%;
  @media ${device.laptopS} { 
    height: 90px;
    }
  .logotype {
    width: 187px;
    height: auto;
    @media ${device.laptopS} { 
      width: 200px;
    }
    @media ${device.laptop} { 
      width: 130px;
    }
  }
`

const StyledTextSectionContainer = styled.section`
  display: flex;
  /* justify-content: end; */
  position: relative;
  .board{
    position: absolute;
   
    width: 46%;
    right: 0%;
    top: 62px;
    /* bottom: 0; */
    /* @media screen and (max-width: 1200px ) {
      display: none;
    } */

    @media ${device.mobileL} { 
    }


    @media ${device.laptop} { 
      width: 42%;
      top: 0;
      right: 0%;
    }
    @media ${device.laptopS} { 
      width: 42%;
      top: 0;
      right: 0%;
    }
    @media ${device.laptopL} { 
      width: 42%;
      top: 18px;
      
      right: 0%;
    }
  }
`
const StyledTextSection = styled.div`
  display: flex;
  max-width: 50%;
  flex-direction: column;
  padding-top: 50px;

  @media ${device.mobileL} { 
    max-width: 100%;
  }


  @media ${device.laptopL} { 
    padding-top:0px;
  }
  @media ${device.laptopS} { 
    padding-top:0px;
  }
  @media ${device.laptop} { 
    padding-top:0px;
  }
  
`
